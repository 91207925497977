<template>
    <Form
        v-if="!tagBindedAuthUser"
        :class="['auth-form', {'auth-form_tag': registerByTag}]"
        :validation-schema="schema"
        @submit="handleSignUpForm"
        @invalid-submit="onInvalidSubmit"
        v-slot="{ errors }"
    >
        <p class="f-20-black f-bold mb-20">Register new account</p>
        <form-input
            id="first_name"
            class="mb-25"
            label="First Name"
            name="first_name"
            placeholder="Your first name"
            :errors="errors"
        />
        <form-input
            id="last_name"
            class="mb-25"
            label="Last Name"
            name="last_name"
            placeholder="Your last name"
            :errors="errors"
        />
        <form-input
            id="email"
            class="mb-25"
            label="Email"
            name="email"
            placeholder="Your email"
            :errors="errors"
        />
        <form-input
            v-if="registerByTag"
            id="confirm_email"
            class="mb-25"
            label="Retype Email"
            name="confirm_email"
            placeholder="Retype your email"
            :errors="errors"
        />
        <div class="mb-25" v-if="registerByTag">
            <p class="f-13-black f-black mb-3">Phone</p>
            <vue-tel-input
                class="w-100"
                :number="phone"
                :markAsRequiredField="true"
                :markAsInvalidField="phoneRequiredError"
                @setPhoneNumber="($e) => setValidNumber($e)"
            />
        </div>
        <form-input
            id="tag"
            class="mb-25"
            label="Tag (skip entry and continue)"
            name="tag"
            placeholder="Your tag"
            disabled="true"
            :errors="errors"
            v-model="schema.tag"
            v-if="schema.tag"
        />
        <form-input
            id="password"
            class="mb-25"
            label="Password"
            name="password"
            placeholder="Your password"
            :errors="errors"
        />
        <form-input
            id="confirm_password"
            class="mb-25"
            label="Retype password"
            name="confirm_password"
            placeholder="Retype password"
            :errors="errors"
        />
        <div :class="['row-between row-baseline', { 'pb-20': !registerByTag }]">
            <p
                v-if="!registerByTag"
                @click="toLoginPage"
                class="lg-6 sm-12 pointer f-18-grey f-medium"
            >
                Already have an account? Log in
            </p>
            <div
                :class="[
                    { 'row-center mt-3': mScreen },
                    { 'column-end lg-12': registerByTag },
                ]"
            >
                <primary-button buttonType="submit">
                    <template v-if="!loading"> Sign Up </template>
                    <template v-else>
                        <loader
                            size="mini"
                            loaderColor="white"
                            class="s-align-5"
                        />
                    </template>
                </primary-button>
                <div
                    v-if="registerByTag"
                    @click="showInstruction = !showInstruction"
                    class="mt-5 relative"
                >
                    <primary-button buttonType="button">
                        Connect product to existing account
                    </primary-button>
                    <p
                        v-if="showInstruction"
                        class="instruction-modal f-16-black"
                    >
                        <span>1) Log in to your account</span>
                        <span
                            >2) After logging in, stay logged in and scan the QR
                            code
                        </span>
                        <span
                            >3) Select "bind" to bind this product to your
                            existing account
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <p
            v-if="!registerByTag"
            class="row-end pt-20 f-18-grey f-medium bt-grey"
        >
            Check email after registering!
        </p>
    </Form>
    <Form
        v-else
        class="auth-form"
        :validation-schema="bindCardSchema"
        @submit="bindCard"
        @invalid-submit="onInvalidSubmit"
        v-slot="{ errors }"
    >
        <p class="f-20-black f-bold mb-20">Bind tag to your primary card</p>
        <form-input
            id="tag"
            class="mb-25"
            label="Tag"
            name="code"
            placeholder="Your tag"
            disabled="true"
            :errors="errors"
            v-model="schema.tag"
        />
        <div class="row-end row-baseline">
            <div :class="{ 'row-center mt-3': mScreen }">
                <primary-button buttonType="submit">
                    <template v-if="!loading"> Bind </template>
                    <template v-else>
                        <loader
                            size="mini"
                            loaderColor="white"
                            class="s-align-5"
                        />
                    </template>
                </primary-button>
            </div>
        </div>
    </Form>
</template>

<script>
import VueTelInput from "@/ui/VueTelInput";
import { Form } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";

export default {
    name: "SignUp",
    components: {
        Form,
        VueTelInput,
    },
    data() {
        // Form validation schema
        const bindCardSchema = yup.object({
            code: yup.string().nullable().label("Tag"),
        });

        return {
            bindCardSchema,
            loading: false,
            showInstruction: false,
            phoneRequiredError: false,
            phoneIsValid: false,
            phone: "",
        };
    },
    computed: {
        ...mapGetters({
            tagBindedAuthUser: "tags/tagBindedAuthUser",
        }),
        registerByTag() {
            return !!this.$route?.query?.tag || null;
        },
        // Dynamic Form validation schema
        schema() {
            let baseRules = {
                first_name: yup.string().required().label("First name"),
                last_name: yup.string().required().label("Last name"),
                email: yup.string().required().email().label("Email"),
                password: yup.string().required().min(8).label("Password"),
                confirm_password: yup
                    .string()
                    .required()
                    .oneOf([yup.ref("password"), null], "Passwords does not match")
                    .label("Retype password"),
            }

            if (this.registerByTag) {
                baseRules["tag"] = yup.string().nullable().label("Tag");
                baseRules["confirm_email"] = yup
                    .string()
                    .required()
                    .oneOf([yup.ref("email"), null], "Email does not match")
                    .label("Retype email");
            }

            return yup.object(baseRules);
        },
    },
    methods: {
        handleSignUpForm(values) {
            let formData = values;
            delete formData.confirm_password;

            if (this.registerByTag) {
                if (!this.phone) {
                    this.phoneRequiredError = true;
                    this.$store.dispatch(
                        "notifications/SHOW_ERROR_NOTIFICATION",
                        "Phone number is a required field"
                    );
                    return;
                }

                if (!this.phoneIsValid) {
                    this.phoneRequiredError = true;
                    this.$store.dispatch(
                        "notifications/SHOW_ERROR_NOTIFICATION",
                        "Invalid phone number"
                    );
                    return;
                }

                formData.phone = this.phone;
                delete formData.confirm_email;
            }

            this.loading = true;

            this.$store
                .dispatch("auth/AUTH_SIGNUP", formData)
                .then((data) => {
                    if (this.$route?.query?.tag) {
                        this.$store.dispatch(
                            "notifications/SHOW_SUCCESS_NOTIFICATION",
                            `Your account has been created!`
                        );

                        localStorage.setItem('user-token', data.access_token);
                        this.$store.commit('auth/AUTH_SUCCESS', data);

                        if (data.email_verified_at) {
                            this.$store.commit('user/SET_AUTH_USER_INFO', data);
                        }

                        this.$router.push({ name: 'Profile' });
                        return;
                    }

                    if (data.id) {
                        this.$store.dispatch(
                            "notifications/SHOW_SUCCESS_NOTIFICATION",
                            `Your account has been registered! \n Please check your email to confirm your email address!`
                        );
                        this.$router.push({ name: "SignUpSuccess" });
                    }
                })
                .catch((error) => {
                    this.$store.dispatch(
                        "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
                        error
                    );
                })
                .finally(() => (this.loading = false));
        },
        bindCard(values) {
            this.loading = true;

            this.$store
                .dispatch("tags/BIND_TAG_TO_CARD", values)
                .then((data) => {
                    if (data.data.url) {
                        this.$store.dispatch(
                            "notifications/SHOW_SUCCESS_NOTIFICATION",
                            `Your profile has been bound successfully!`
                        );
                        this.$router.push({ name: "Profile" });
                    }
                })
                .catch((error) => {
                    this.$store.dispatch(
                        "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
                        error
                    );
                })
                .finally(() => (this.loading = false));
        },
        onInvalidSubmit({ values, errors, results }) {
            /* values -> current form values
             * errors -> a map of field names and their first error message
             * results ->  a detailed map of field names and their validation results
             */
            this.$store.dispatch(
                "notifications/SHOW_VALIDATION_ERROR_NOTIFICATION",
                errors
            );

            if (this.registerByTag) {
                if (!this.phone) {
                    this.phoneRequiredError = true;
                    this.$store.dispatch(
                        "notifications/SHOW_ERROR_NOTIFICATION",
                        "Phone number is a required field"
                    );
                    return;
                }

                if (!this.phoneIsValid) {
                    this.phoneRequiredError = true;
                    this.$store.dispatch(
                        "notifications/SHOW_ERROR_NOTIFICATION",
                        "Invalid phone number"
                    );
                    return;
                }
            }
        },
        toLoginPage() {
            this.$router.push({ name: "Login" });
        },
        setValidNumber(data) {
            this.phone = data.number;

            if (data.isValid) {
                this.phoneRequiredError = false;
                this.phoneIsValid = true;
                return;
            } else {
                this.phoneIsValid = false;
                this.phoneRequiredError = true;
            }
        },
    },
    mounted() {
        this.schema.tag = this.$route?.query?.tag || null;
    },
};
</script>
